import { ButtonDarkPurple } from "components/styled-component/ButtonStyled";
import { PriceNumber } from "functions/index";
const ButtonLabelsPrice = (props) => {
  return (
    <ButtonDarkPurple>
      <div
        style={{
          fontWeight: 600,
          fontSize: "16px",
          color: "#ffff",
        }}
      >
        {`฿ ${PriceNumber(props.price)}`}
      </div>
    </ButtonDarkPurple>
  );
};

export default ButtonLabelsPrice;
