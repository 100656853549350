import styled from "styled-components";

const LabelRocommendStyled = styled.div`
  background-color: #fbae18;
  color: #c72901;
  border-radius: 5px;
  width: 100%;
  padding: 3px;
  text-align: center;
  font-weight: 600;
`;
export default LabelRocommendStyled;
