import { createContext, useReducer } from "react";
export const CouterCartContext = createContext({});

let mergeProduct = localStorage.getItem(`mergeProduct`);
const arr = mergeProduct ? JSON.parse(mergeProduct) : [];
const arrAmount = arr.map((item) => item.amount);
const inititalCouterCart = arrAmount.reduce(
  (partialSum, a) => partialSum + a,
  0
);

const initialState = {
  openModal: false,
  counter: inititalCouterCart,
  productDetail: {},
  product: {
    amount: 1,
    product_list_id: "",
  },
  productSet: {
    set_type: "",
    box_id: 0,
    pod_id: 0,
    mod_id: 0,
    atom_id: 0,
    cotton_id: 0,
    charger_id: [],
    battery_id: 0,
    amount: 1,
  },
  sideMenu: [],
  selectedMenu: {
    header: "สินค้าทั้งหมด",
    catId: "",
    submenu: "",
    api: "",
  },
  currentCart: [],
  totalCart: 0,
  shipment_price: 0,
};

const couterCartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_PRODUCT":
      const { amount } = action.payload;
      const localProduct = JSON.parse(localStorage.getItem(`localProduct`));
      const filterData = {
        products: localProduct.products.map((item) => {
          return {
            ...item,
            product_type: "products",
          };
        }),
        product_set: localProduct.product_set.map((item) => {
          return {
            ...item,
            product_type: "product_set",
          };
        }),
      };

      const mergeProduct = [...filterData.products, ...filterData.product_set];
      localStorage.setItem("mergeProduct", JSON.stringify(mergeProduct));
      return {
        ...state,
        counter: state.counter + amount,
        openModal: true,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        openModal: false,
      };
    case "DELETE_CART":
      return {
        ...state,
        counter: state.counter - action.payload,
      };
    case "SET_COUTER_CART":
      return {
        ...state,
        counter: action.payload,
      };
    case "ADD_CART":
      return {
        ...state,
        counter: state.counter + action.payload,
      };
    case "READ_PRODUCT_DETAIL":
      const { product_in_set } = action.payload;
      return {
        ...state,
        productDetail: { ...action.payload, product_in_set: product_in_set ?? []  },
      };
    case "SET_CUSTOM_BOX":
      return {
        ...state,
        productSet: action.payload,
      };
    case "RESET_CUSTOM_BOX":
      return {
        ...state,
        productSet: {
          set_type: state.productSet.set_type,
          box_id: 0,
          pod_id: 0,
          mod_id: 0,
          atom_id: 0,
          cotton_id: 0,
          charger_id: [],
          battery_id: 0,
          amount: 1,
        },
      };
    case "SET_PRODUCT":
      return {
        ...state,
        product: action.payload,
      };
    case "GET_SIDE_MENU":
      return {
        ...state,
        sideMenu: action.payload,
      };
    case "SET_CURRENT_CAT":
      return {
        ...state,
        selectedMenu: action.payload,
      };
    case "SET_CURRENT_CART":
      return {
        ...state,
        currentCart: action.payload,
      };
    case "SET_SHIPMENT_PRICE":
      return {
        ...state,
        shipment_price: action.payload,
      };
    case "CAL_TOTAL_CART":
      const data = action.payload;
      const total = data.price * data.amount;
      return {
        ...state,
        totalCart: state.totalCart + total,
      };
    default:
      break;
  }
};
export const CounterCartProvider = ({ children }) => {
  const [counterState, counterDispatch] = useReducer(
    couterCartReducer,
    initialState
  );

  const {
    counter,
    productDetail,
    productSet,
    openModal,
    sideMenu,
    selectedMenu,
    currentCart,
    product,
    shipment_price,
  } = counterState;

  const addProduct = (payload) =>
    counterDispatch({ type: "ADD_PRODUCT", payload }); // ส่ง type ADD_COUNTER และ payload เพื่อให้ conterReducer ไปใช้งานต่อ
  const deleteProductInCart = (payload) =>
    counterDispatch({ type: "DELETE_CART", payload }); // ส่ง type SUB_COUNTER และ payload เพื่อให้ conterReducer ไปใช้งานต่อ
  const addProductInCart = (payload) =>
    counterDispatch({ type: "ADD_CART", payload });
  const readProductDetail = (payload) =>
    counterDispatch({ type: "READ_PRODUCT_DETAIL", payload });
  const setCustomSet = (payload) =>
    counterDispatch({ type: "SET_CUSTOM_BOX", payload });
  const reducerSetSideMenu = (payload) =>
    counterDispatch({ type: "GET_SIDE_MENU", payload });
  const reducerSetSelectedmenu = (payload) =>
    counterDispatch({ type: "SET_CURRENT_CAT", payload });
  const reducerSetCurrentCart = (payload) =>
    counterDispatch({ type: "SET_CURRENT_CART", payload });
  const reducerSetCouterCart = (payload) =>
    counterDispatch({ type: "SET_COUTER_CART", payload });
  const reducerCalTotal = (payload) =>
    counterDispatch({ type: "CAL_TOTAL_CART", payload });
  const reducerShipmentPrice = (payload) =>
    counterDispatch({ type: "SET_SHIPMENT_PRICE", payload });
  const resetCustomsetBox = () => counterDispatch({ type: "RESET_CUSTOM_BOX" });
  const setProduct = (payload) =>
    counterDispatch({ type: "SET_PRODUCT", payload });
  const reducerCloseModal = () => counterDispatch({ type: "CLOSE_MODAL" });

  return (
    <CouterCartContext.Provider
      value={{
        counter,
        productDetail,
        addProduct,
        deleteProductInCart,
        addProductInCart,
        readProductDetail,
        productSet,
        setCustomSet,
        openModal,
        sideMenu,
        reducerSetSideMenu,
        selectedMenu,
        reducerSetSelectedmenu,
        currentCart,
        resetCustomsetBox,
        reducerSetCurrentCart,
        reducerSetCouterCart,
        reducerCloseModal,
        reducerCalTotal,
        setProduct,
        product,
        reducerShipmentPrice,
        shipment_price,
      }}
    >
      {children}
    </CouterCartContext.Provider>
  );
};
