import styled from "styled-components";

const LabelSaleStyled = styled.div`
  background-color: #c72901;
  color: #f3e34c;
  border-radius: 5px;
  width: fit-content;
  padding: 3px;
  text-align: center;
  font-weight: 600;
  font-aize: 12px;
`;
export default LabelSaleStyled;
