import { message } from "antd";
import { AXIOS } from "services/host";
import { API_CART } from "services/apis";

const UpdateCart = (product, type, callBackSuccess) => {
  const loading = message.loading("กำลังเพิ่มสินค้าเข้าตะกร้า..", 0);
  const localProduct = JSON.parse(localStorage.getItem(`localProduct`)) ?? {
    products: [],
    product_set: [],
  };
  const tmp = localProduct.products;
  const isExist = tmp.findIndex(
    (item) => item.product_list_id === product.product_list_id
  );
  if (isExist >= 0) {
    const item = tmp[isExist];
    item.amount = item.amount + product.amount;
    const result = { ...localProduct, [type]: [...tmp] };
    return AXIOS({
      method: "post",
      url: API_CART,
      data: result,
    })
      .then((res) => {
        const respondData = res.data.data;
        loading();
        localStorage.setItem(`resultGetCart`, JSON.stringify(respondData));
        localStorage.setItem(`localProduct`, JSON.stringify(result));
        message.success("เพิ่มสินค้าเข้าตะกร้าสำเร็จ", 1);
        callBackSuccess(product.amount);
      })
      .catch((err) => {
        loading();
        message.error("เพิ่มสินค้าเข้าตะกร้าไม่สำเร็จ", 1);
      });
  } else {
    const objItems = localProduct[type];
    const result = { ...localProduct, [type]: [...objItems, product] };
    return AXIOS({
      method: "post",
      url: API_CART,
      data: result,
    })
      .then((res) => {
        const respondData = res.data.data;
        loading();
        localStorage.setItem(`resultGetCart`, JSON.stringify(respondData));
        localStorage.setItem(`localProduct`, JSON.stringify(result));
        message.success("เพิ่มสินค้าเข้าตะกร้าสำเร็จ", 1);
        callBackSuccess(product.amount, result);
      })
      .catch((err) => {
        loading();
        message.error("เพิ่มสินค้าเข้าตะกร้าไม่สำเร็จ", 1);
      });
  }
};

export default UpdateCart;
