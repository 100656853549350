import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Button } from "antd";
const ButtonAddToCart = (props) => {
  return (
    <ButtonOrange
      disabled={props.disabled}
      type={props.size}
      onClick={props.onClick}
      style={props.style}
    >
      เพิ่มเข้าตะกร้า
      <FontAwesomeIcon style={{ marginLeft: "2px" }} icon={faShoppingBasket} />
    </ButtonOrange>
  );
};

export default ButtonAddToCart;
const ButtonOrange = styled(Button)`
  font-size: ${(props) => props.type ?? "12px"};
  height: 2rem;
  border-radius: 5px;
  background-color: #f26440;
  color: #ffffff;
  border: unset;
  font-weight: 500;
  width: 100%;
  @media (max-width: 6zs00px) {
    padding: 0px;
  }
  cursor: pointer;
  :hover {
    background-color: #ffe4dc;
    color: #f26440;
  }
  :focus {
    background-color: #f26440;
    color: #ffffff;
    border: unset;
  }
`;
