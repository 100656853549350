import React, { useEffect, useState, useContext } from "react";
import { Menu, Grid, Collapse } from "antd";
import { CouterCartContext } from "store/CouterCartProvider";
import { AXIOS } from "services/host";
import { API_CATEGORY } from "services/apis";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UnorderedListOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;
const { Panel } = Collapse;

function SideMenu(props) {
  const [keyCollapse, setKeyCollapse] = useState([]);
  const { sideMenu, reducerSetSideMenu } = useContext(CouterCartContext);
  const screens = useBreakpoint();
  let location = useLocation();
  const navigate = useNavigate();
  const { catId } = useParams();

  const onSelect = (menuItem) => {
    const pathname = location.pathname;
    if (pathname === "/" || pathname.includes("product")) {
      if (menuItem.id === "home") {
        navigate(`/`);
      } else {
        navigate(`/product/${menuItem.name}/category/${menuItem.id}`);
      }
    } else {
      if (menuItem.id === "home") {
        navigate(`/all`);
      } else {
        navigate(`/all/${menuItem.name}/categoryId/${menuItem.id}`);
      }
    }
  };

  const onCheckSelectedKey = () => {
    if (location.pathname === "/" || location.pathname === "/all") {
      return ["home"];
    } else {
      return [String(catId)];
    }
  };
  useEffect(() => {
    const fetchData = () => {
      AXIOS({
        url: API_CATEGORY,
        method: "get",
      })
        .then((res) => {
          const all = { id: "home", name: "สินค้าทั้งหมด", sub_categories: [] };
          const data = res.data.data;
          const dataPutAllProduct = data.map((item) => {
            const obj = { id: item.id, name: `${item.name}ทั้งหมด` };
            item.sub_categories.unshift(obj);
            return item;
          });
          const result = [all, ...dataPutAllProduct];
          reducerSetSideMenu(result);
        })
        .catch((err) => {
          reducerSetSideMenu([]);
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menu = (
    <MenuStyle mode="inline" selectedKeys={onCheckSelectedKey()}>
      {sideMenu.map((item) =>
        item.sub_categories.length > 1 ? (
          <SubMenu key={item.id} title={item.name}>
            {item.sub_categories.map((sub) => (
              <Menu.Item
                onClick={() => {
                  onSelect(sub);
                }}
                key={sub.id}
              >
                {sub.name}
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item
            key={item.id}
            onClick={() => {
              onSelect(item);
            }}
          >
            {item.name}
          </Menu.Item>
        )
      )}
    </MenuStyle>
  );
  return (
    <div>
      {screens?.xs === true ? (
        <CollapseStyle
          ghost
          activeKey={keyCollapse}
          onChange={(e) => {
            setKeyCollapse(e.length === 0 ? [] : ["1"]);
          }}
          expandIconPosition="left"
        >
          <Panel key="1" extra={<UnorderedListOutlined />}>
            {menu}
          </Panel>
        </CollapseStyle>
      ) : (
        menu
      )}
    </div>
  );
}

export default SideMenu;

const CollapseStyle = styled(Collapse)`
  .ant-collapse-header {
    background: #fbfafe !important;
    color: #12143e !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: unset;
  }
`;
const MenuStyle = styled(Menu)`
  background: #fbfafe;
  border-right: unset !important;
  color: #12143e;
  .ant-menu-item:hover {
    color: #7b40f8;
  }

  .ant-menu-submenu-title:hover {
    color: #7b40f8;
  }
  .ant-menu-item-selected {
    color: #7b40f8;
    background: #edeafd !important;
  }
  .ant-menu-submenu-selected {
    color: #5f2083;
    font-size: 16px;
    font-weight: 500;
  }
  .ant-menu-item::after {
    border-right: 3px solid #7b40f8;
  }
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open {
    color: #12143e;
    background: #ffffff !important;
  }
`;
