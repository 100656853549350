import styled from "styled-components";

const LabelPriceModalStyled = styled.div`
  background-color: white;
  color: #491079;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
`;
export default LabelPriceModalStyled;
