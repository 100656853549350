import styled from "styled-components";
import { Button } from "antd";
const ButtonOptionsStyled = styled(Button)`
  background-color: #f0f5ff;
  color: #1d39c4;
  border: 1px solid #adc6ff;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  :hover {
    background-color: white;
    color: #4a67fa;
    border: 1px solid #7d93ff;
  }
  :focus {
    background-color: #4a67fa;
    color: #f0f5ff;
    border: 1px solid #4a67fa;
  }
  @media (max-width: 600px) {
    font-size: 12px;
    padding: 5px;
    border-radius: 3px;
    height: 2rem;
  }
`;
export default ButtonOptionsStyled;
